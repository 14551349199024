export const LOCALSTORAGE_KEY = 'tt-info'
export const ACTIVE_TENANT = 'tt-active'
export const TOASTER_KEY = 'toast'
export enum FROM {
    bookedLoads = 'bookedLoads',
    loadRequests = 'loadRequests'
}
export const MAX_FILE_SIZE = 5242880;
export const SELECTED_LOAD_ID_KEY = 'selectedLoadId';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;
